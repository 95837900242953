const currentUrl = document.currentScript?.getAttribute('src')
if (!currentUrl) throw new Error('Could not determine current URL')

const baseUrl = new URL(currentUrl, window.location.href).origin

type CloseMessage = { type: 'close' }
type TokenMessage = { type: 'success'; redirect?: string }
type EventMessage = { type: 'event'; eventType: string; detail?: unknown }
type Message = CloseMessage | TokenMessage | EventMessage

const createIframe = () => {
  const iframe = document.createElement('iframe')

  iframe.id = 'gf-landing-page-connect-overlay'
  iframe.src = baseUrl

  Object.assign(iframe.style, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '9900'
  })

  const style = document.createElement('style')
  style.setAttribute('type', 'text/css')
  style.innerHTML = `#${iframe.id} {
    border: 0;
    background-color: rgba(0,0,0,0.5);
    left: 0;
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
  }`

  document.head.prepend(style)
  document.body.appendChild(iframe)
  document.body.style.overflow = 'hidden'

  const tidyUp = () => {
    document.body.removeChild(iframe)
    document.head.removeChild(style)
    document.body.style.overflow = 'auto'
  }

  return { iframe, tidyUp }
}

export default (
  gameId: string,
  gameEnvironmentId: string,
  gameImageUrl?: string,
  locale?: string
) => {
  const { iframe, tidyUp } = createIframe()

  const sendMessage = (type: string, payload: unknown) => {
    /* istanbul ignore if */
    if (!iframe.contentWindow) throw new Error('iframe has no contentWindow')

    iframe.contentWindow.postMessage({ type, payload }, baseUrl)
  }

  iframe.addEventListener('load', () => {
    sendMessage('show', { gameId, gameEnvironmentId, gameImageUrl, locale })
  })

  return new Promise<void>((resolve, reject) => {
    const handleMessage = (e: MessageEvent<Message>) => {
      if (e.origin !== baseUrl) return

      const { data } = e

      if (data.type === 'event') {
        const { eventType, detail } = data

        return window.dispatchEvent(new CustomEvent(eventType, { detail }))
      }

      window.removeEventListener('message', handleMessage)

      data.type === 'success' ? resolve() : reject()

      if ('redirect' in data && data.redirect)
        window.location.href = data.redirect
    }

    window.addEventListener('message', handleMessage)
  }).finally(tidyUp)
}
